@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/part2-bg.png");
  height: 100%;
  min-height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.logIn-logo{
  height: 100%;
  max-height: 11rem;
  width: 100%;
  max-width: 11rem;
  margin-top: 5rem !important;
}
.raffle-num{
  background-image: url("./images/lantern.png");
  min-height: 30vh;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size:auto;
}
/* .draw{
  font-size: 2rem;
  font-weight: 800;
  border-radius: 5rem;
} */
.draw-image{
  box-shadow: 2px 2px 4px #000000;
}
.swal2-title{
  font-size: 1.5rem !important;
}
/* Login */
h1{
    text-align: center;
    color: white;
    font-weight: 600;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
  color: white !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color:  white !important;
}
.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide span{
  font-weight: 600;
}
.btn-logIn{
  font-weight: 600;
}
/* Navbar */
p{
  display: none;
}
.logo{
  max-width: 10rem !important;
  max-height: 10rem !important;
}
.table-trophy{
  max-width: none !important;
}
.modal-dialog{
  max-width: none !important;
  margin:5rem !important;
}
.badge{
  position: absolute;
  left: 40%;
  font-size: .75rem;
}
.badges{
  position: relative;
  width: 3rem;

}
.navbar-light .navbar-nav .nav-link{
  color: white !important;
  font-size: 1.5rem;
  margin-left: 1rem;
}
.navbar-brand{
  color: white !important;
  font-size: 1.5rem;
}
.Navbar{
  position: absolute !important;
  background-color: transparent !important;
}
.bg-trans .modal-dialog .modal-content{
  background-color: transparent !important;
  border: none !important;
  max-width: 50% !important;
}
.bg-trans .modal-dialog{
  display: flex;
  justify-content: center;
}
.login-mail{
  font-weight: 300;
}
.navbar-brand span{
  font-weight: 600;
}

/* App */
.asdf{
  position: absolute;
  top: 30%;
}
.jkl{
  position: absolute;
  top: 30%;
}
.mode{
  width: 40% !important;
  position: absolute !important;
  left: 30% !important;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #dc3545;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.pagination-data{
  padding: 0;
  margin: 0;
}
.pagination-data li{
  list-style:none;
}
.table-filter-info{
  padding : 15px;
}
.thead-primary tr th {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}

.rc-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.rc-pagination-item,
.rc-pagination-prev, 
.rc-pagination-jump-prev, 
.rc-pagination-jump-next {
    margin-right: 8px;
}

.rc-pagination-total-text{
  margin-right: 12px;
  cursor: initial;
}

.rc-pagination-jump-next, 
.rc-pagination-jump-prev, 
.rc-pagination-next, 
.rc-pagination-prev {
    display: inline-block;
    min-width: 28px;
    height: 28px;
    color: rgba(0,0,0,.85);
    font-family: Arial;
    line-height: 28px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all .3s;
}
.rc-pagination-jump-next button, 
.rc-pagination-jump-prev button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #666;
}
.rc-pagination-jump-next button:after, 
.rc-pagination-jump-prev button:after {
    display: block;
    content: "•••";
}
.rc-pagination-item, 
.rc-pagination-prev, 
.rc-pagination-next, 
.rc-pagination-total-text {
    min-width: initial;
    height: auto;
    line-height: initial;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.rc-pagination-item a, 
.rc-pagination-item button, 
.rc-pagination-prev a, 
.rc-pagination-prev button, 
.rc-pagination-next a, 
.rc-pagination-next button,
.rc-pagination-total-text a, 
.rc-pagination-total-text button {
    padding: 6px 8px;
    height: auto;
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: #656f84 !important;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
}
.rc-pagination-item.rc-pagination-item-active a, 
.rc-pagination-item.rc-pagination-item-active a:hover, 
.rc-pagination-prev.rc-pagination-item-active a, 
.rc-pagination-prev.rc-pagination-item-active a:hover, 
.rc-pagination-next.rc-pagination-item-active a, 
.rc-pagination-next.rc-pagination-item-active a:hover, 
.rc-pagination-total-text.rc-pagination-item-active a, 
.rc-pagination-total-text.rc-pagination-item-active a:hover {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #ffffff !important;
}
.rc-pagination-item a:hover, 
.rc-pagination-item button:hover, 
.rc-pagination-prev a:hover, 
.rc-pagination-prev button:hover, 
.rc-pagination-next a:hover, 
.rc-pagination-next button:hover, 
.rc-pagination-total-text a:hover, 
.rc-pagination-total-text button:hover {
    background-color: #eceff5;
    border-color: #eceff5;
}
.thead-primary tr th{
  border: none !important;
  font-weight: 500;
}
#player_value{
  height: 3rem !important;
}

@media (max-width: 991px) {
  h4{
    font-size: 1rem;
  }
  .logo{
    height: 4rem;
  }
  p{
    display: block;
  }
  .hide{
    display: none !important;
  }
  .Phone{
    background-color: #ffffff !important;
  }
  .navbar-light .navbar-nav .nav-link{
  color: rgb(0, 0, 0) !important;
  font-size: 1.5rem;
  margin-left: 1rem;
  text-align: center;
}
.navbar-light .navbar-nav{
  display: flex !important;
}
.navbar-brand{
  color: white !important;
  font-size: 2rem;
}
.navbar-brand{
  color: rgb(0, 0, 0) !important;
  font-size: 2rem;
}
}